import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Mohan Farms, New Delhi",
  address: "New Delhi",
  client: "",
  area: "10.0 Acres",
  project: "Mohan Farms, New Delhi",
  category: "Landscape | Architecture",
  status: "Completed",
  backlink: "/projects/landscape/",
};

export default class Projectmohanfarms extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Landscape Projects | Mohan Farms, Delhi"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the bespoke landscape design and outdoors- including a giant chess set- commissioned by a farmhouse in Delhi"
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
