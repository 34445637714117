const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/1.png",
    text: "1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/2.png",
    text: "2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/3.png",
    text: "3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/4.png",
    text: "4",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/5.png",
    text: "5",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/6.png",
    text: "6",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/7.png",
    text: "7",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/8.png",
    text: "8",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/9.png",
    text: "9",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/10.png",
    text: "10",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/11.png",
    text: "11",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/12.png",
    text: "12",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/13.png",
    text: "13",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/14.png",
    text: "14",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/15.png",
    text: "15",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/16.png",
    text: "16",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/17.png",
    text: "17",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/18.png",
    text: "18",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/19.png",
    text: "19",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/20.png",
    text: "20",
  },
];

export default data;
